import api from '@/services/api';
import { make } from 'vuex-pathify';

import { map, fromPairs } from 'lodash';
const returnDataFromULdk = data => {
  const lines = data.match(/[^\r\n]+/g);
  if (lines.length <= 1) {
    return {};
  } else {
    lines.shift(0);
    return fromPairs(
      map(lines, line => {
        const lineSplit = line.split('|');
        return [lineSplit[1], { value: lineSplit[1], text: lineSplit[0] }];
      })
    );
  }
};

const state = {
  community: null,
  toolStatus: {
    isGeolocationToolActive: false,
  },
};

const mutations = {
  ...make.mutations(state),
};

const actions = {
  async getUldkCommunities(store, district) {
    return fetch(`https://uldk.gugik.gov.pl/service.php?obiekt=gmina&wynik=gmina,teryt&teryt=${district}`)
      .then(r => r.text())
      .then(returnDataFromULdk);
  },
  async getUldkPrecincts(store, community) {
    return fetch(`https://uldk.gugik.gov.pl/service.php?obiekt=obreb&wynik=obreb,teryt&teryt=${community}`)
      .then(r => r.text())
      .then(returnDataFromULdk);
  },
  async searchUldkParcel(store, { territ, resultSrid = '3857' }) {
    return fetch(
      `https://uldk.gugik.gov.pl/service.php?obiekt=dzialka&wynik=numer,teryt,geom_wkt&teryt=${territ}&srid=${resultSrid}`
    )
      .then(r => r.text())
      .then(data => {
        const lines = data.match(/[^\r\n]+/g);
        if (lines.length <= 1) {
          return [];
        } else {
          if (lines[0].indexOf('-1') >= 0) {
            return [];
          }
          lines.shift(0);
          return map(lines, line => line.split('|'));
        }
      });
  },

  async searchAddressByGugik(store, payload) {
    const { query, prefix } = payload;
    const address = `${prefix}${query}`;
    const res = await fetch(
      `https://services.gugik.gov.pl/uug/?srid=3857&request=GetAddress&` +
        new URLSearchParams({
          address: address,
        })
    ).then(response => response.json());
    const results = res.results;
    const mappedResults = results
      ? Object.values(results).map(v => {
          return {
            ...v,
            geometry: {
              coordinates: [parseFloat(v.x), parseFloat(v.y)],
              crs: {
                properties: {
                  name: 'EPSG:3857',
                },
                type: 'name',
              },
              type: 'Point',
            },
            description: prefix
              ? `${v.street ? `${v.street}` : ''}${v.number ? ` ${v.number}` : ''}`
              : `${v.city}${v.street ? `, ${v.street}` : ''}${v.number ? ` ${v.number}` : ''}`,
          };
        })
      : null;
    return results === null ? [] : mappedResults;
  },
  async searchAddressBySystem(store, params) {
    return (await api.get(`addresses/search`, { params })).data.data;
  },
  async searchAddressByGoogle(store, payload) {
    const r = await api.post('google/geocode', payload);
    return r.data.data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
