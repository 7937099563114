import store from '@/store';

const beforeAdminRouteEnter = async (to, from, next, callback) => {
  const { dispatch } = store;
  const isTokenValid = await dispatch('authentication/checkToken');
  if (isTokenValid) {
    callback();
  } else {
    const { set } = store;
    set('authentication/SET_TOKEN!', null);
    next({
      name: 'login',
    });
  }
};

export { beforeAdminRouteEnter };
