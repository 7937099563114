import Vue from 'vue';
import Vuex from 'vuex';
import pathify from 'vuex-pathify';

import admin from '@/store/admin';
import authentication from '@/store/authentication';
import map from '@/store/map';
import sidebar from '@/store/sidebar';
import snackbar from '@/store/snackbar';
import tools from '@/store/tools';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [pathify.plugin],
  modules: { admin, authentication, map, sidebar, snackbar, tools },
});
