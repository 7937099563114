const capitalize = string => {
  if (typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};
const defaultHashGenerator = object => {
  return getHash(
    objectToArray(object)
      .map(value => {
        if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
          return objectToArray(value);
        }
        return value;
      })
      .join('')
  );
};
const filterObjectKeys = (obj = {}, { allowedKeys, notAllowedKeys } = {}) => {
  return Object.keys(obj)
    .filter(key => {
      return (!allowedKeys || allowedKeys.includes(key)) && !(notAllowedKeys && notAllowedKeys.includes(key));
    })
    .reduce((rObj, key) => {
      rObj[key] = obj[key];
      return rObj;
    }, {});
};
const getFlatGroupsLayers = (elements = []) => {
  const groups = [];
  const layers = [];
  for (const element of elements) {
    if (element.layers) {
      groups.push(element);
      for (const layer of element.layers) {
        layers.push(layer);
      }
    } else {
      layers.push(element);
    }
  }
  return { groups, layers };
};
const getHash = string => {
  let hash = 0,
    i,
    chr;
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return hash;
};
const importAll = (r, cacheObject = {}) => {
  return r.keys().reduce((total, current) => {
    return { ...total, [current]: r(current) };
  }, cacheObject);
};
const memoizer = (f, { hashGenerator = defaultHashGenerator, cacheObject } = {}) => {
  const cache = cacheObject || {};
  return (argumentsObject = {}) => {
    const hashedArguments = hashGenerator(argumentsObject);
    if (cache[hashedArguments]) {
      return cache[hashedArguments];
    } else {
      const result = f(argumentsObject);
      cache[hashedArguments] = result;
      return result;
    }
  };
};
const objectToArray = object => {
  return Object.keys(object).map(key => {
    return object[key];
  });
};
const sortObjectsByAttribute = (array, attribute, exclude, number = false) => {
  return array.sort((a, b) => {
    if (exclude && a[exclude.name] === exclude.value) {
      return -1;
    } else if (exclude && b[exclude.name] === exclude.value) {
      return 1;
    } else {
      return number
        ? b[attribute] - a[attribute]
        : a[attribute]?.toString().toLowerCase().localeCompare(b[attribute]?.toString().toLowerCase());
    }
  });
};
export {
  capitalize,
  filterObjectKeys,
  getFlatGroupsLayers,
  importAll,
  memoizer,
  objectToArray,
  sortObjectsByAttribute,
};
