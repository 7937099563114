import Vue from 'vue';
import Vuetify from 'vuetify';
import colors from '@/assets/styles/_export.scss';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: colors,
    },
  },
  lang: {
    current: 'pl',
  },
});
