import Vue from 'vue';
import VueRouter from 'vue-router';

import { beforeAdminRouteEnter } from '@/assets/js/routerUtility';

Vue.use(VueRouter);

const routes = [
  {
    path: '',
    name: 'main',
    component: () => import('@/views/MainView'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView'),
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/AdminView'),
    beforeEnter: (to, from, next) => {
      beforeAdminRouteEnter(to, from, next, () => next());
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
