import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import i18n from '@/plugins/i18n';

import vuetify from '@/plugins/vuetify';
import utility from '@/plugins/utility';
import variables from '@/plugins/variables';

Vue.config.productionTip = false;

Vue.use(utility);
Vue.use(variables);

new Vue({
  i18n,
  name: 'mainJs',
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
